//分类
export const data = {
    //分类
    Option: [{
            name: "普工",
            id: "general"
        },
        {
            name: "技能人才",
            id: "mechanic"
        },
        {
            name: "文职/管理",
            id: "manage"
        },
        {
            name: "市场营销",
            id: "marketing"
        },
        {
            name: "物流电商",
            id: "logistics"
        },
        {
            name: "物业后勤",
            id: "property"
        },
    ],
    //有效期
    indate: [{
            name: "1个月",
            id: "1个月"
        },
        {
            name: "3个月",
            id: "3个月"
        },
        {
            name: "6个月",
            id: "6个月"
        },

        {
            name: "1年",
            id: "1年"
        },

    ],
    //性别
    gender: [{
            name: "男",
            id: "1"
        },
        {
            name: "女",
            id: "2"
        },
        {
            name: "男女不限",
            id: "3"
        },

    ],
    //学历要求
    education: [{
            name: "不限",
            id: "不限"
        },
        {
            name: "小学",
            id: "小学"
        },
        {
            name: "初中",
            id: "初中"
        },
        {
            name: "高中",
            id: "高中"
        },
        {
            name: "中专",
            id: "中专"
        },
        {
            name: "大专",
            id: "大专"
        },
        {
            name: "本科",
            id: "本科"
        },
        {
            name: "硕士",
            id: "硕士"
        },
        {
            name: "博士",
            id: "博士"
        },
        {
            name: "博士后",
            id: "博士后"
        },

    ],
    //工作经验要求
    minExperience: [{
            name: "不限",
            id: "不限"
        },
        {
            name: "一年以下",
            id: "一年以下"
        },
        {
            name: "1-3年",
            id: "1-3年"
        },
        {
            name: "3-5年",
            id: "3-5年"
        },
        {
            name: "5年以上",
            id: "5年以上"
        },
    ],

    //地级数据
    workArea_data: [{
            value: '广州市',
            label: '广州市',
            children: [{
                    value: '白云区',
                    label: '白云区',
                    children: [{
                        value: '其他',
                        label: '其他',
                    }]
                },
                {
                    value: '荔湾区',
                    label: '荔湾区',
                    children: [{
                        value: '其他',
                        label: '其他',
                    }]
                },
                {
                    value: '越秀区',
                    label: '越秀区',
                    children: [{
                        value: '其他',
                        label: '其他',
                    }]
                },
                {
                    value: '海珠区',
                    label: '海珠区',
                    children: [{
                        value: '其他',
                        label: '其他',
                    }]
                },
                {
                    value: '花都区',
                    label: '花都区',
                    children: [{
                        value: '其他',
                        label: '其他',
                    }]
                },
                {
                    value: '天河区',
                    label: '天河区',
                    children: [{
                        value: '其他',
                        label: '其他',
                    }]
                },
                {
                    value: '南沙区',
                    label: '南沙区',
                    children: [{
                        value: '其他',
                        label: '其他',
                    }]
                },
                {
                    value: '增城区',
                    label: '增城区',
                    children: [{
                            value: '增城工业区',
                            label: '增城工业区',
                        },
                        {
                            value: '新塘',
                            label: '新塘',
                        },
                        {
                            value: '其他',
                            label: '其他',
                        }
                    ]
                },
                {
                    value: '从化区',
                    label: '从化区',
                    children: [{
                        value: '其他',
                        label: '其他',
                    }]
                },
                {
                    value: '番禺区',
                    label: '番禺区',
                    children: [{
                        value: '其他',
                        label: '其他',
                    }]
                },
                {
                    value: '其他',
                    label: '其他',
                },
            ],
        },
        {
            value: '东莞市',
            label: '东莞市',
            children: [{
                    value: '麻涌镇',
                    label: '麻涌镇',
                    children: [{
                            value: '新沙港',
                            label: '新沙港',
                        },
                        {
                            value: '其他',
                            label: '其他',
                        },
                    ],
                },
                {
                    value: '其他',
                    label: '其他',
                },
            ],
        },
        {
            value: '深圳市',
            label: '深圳市',
            children: [{
                value: '其他',
                label: '其他',
            }, ],
        },
        {
            value: '其他',
            label: '其他',
            children: [],
        },


    ]
}