<!-- 小时工页面 -->
<template>
  <div class="job_Temporary">
    <header>
      <Button size="small" type="warning" @click="$router.back()">
        <Icon type="ios-arrow-back" />
        返回
      </Button>
      <Divider type="vertical" />
      <p style="font-size:18px">{{this.$route.meta.title}}</p>
    </header>
    <section>
      <Spin size="large" fix v-if="spinShow"></Spin>
      <div class="content">
        <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="120">

          <FormItem label="职位名称" prop="name">
            <Input v-model="formValidate.name" maxlength="8" placeholder="职位名称(长度8)"></Input>
          </FormItem>

          <FormItem label="工作时长" prop="workhours">
            <Input v-model="formValidate.workhours" placeholder="请输入如：一周，长期，时间区间等"></Input>
          </FormItem>

          <FormItem label="每日工作时间" prop="daytime">
            <Select v-model="formValidate.daytime" placeholder="每日工作时间">
              <Option :value="item.id" v-for="item in daytime" :key="item.id">{{item.name}}</Option>
            </Select>
          </FormItem>

          <FormItem label="排班安排">
            <Select v-model="formValidate.order" placeholder="排班安排">
              <Option :value="item.id" v-for="item in order" :key="item.id">{{item.name}}</Option>
            </Select>
          </FormItem>

          <FormItem label="工作经验要求">
            <Select v-model="formValidate.minExperience" placeholder="工作经验要求">
              <Option :value="item.id" v-for="item in minExperience" :key="item.id">{{item.name}}</Option>
            </Select>
          </FormItem>

          <FormItem label="学历要求">
            <Select v-model="formValidate.education" placeholder="学历要求">
              <Option :value="item.id" v-for="item in education" :key="item.id">{{item.name}}</Option>
            </Select>
          </FormItem>

          <!-- <FormItem label="所属片区" prop="workArea">
            <Cascader :data="workArea_data" trigger="hover" v-model="formValidate.workArea"></Cascader>
          </FormItem>

          <FormItem label="所属街道" prop="belongingStreet" v-if="formValidate.workArea[1] == '黄埔区' &&formValidate.workArea[2] != '其他'">
            <Select v-model="formValidate.belongingStreet" style="width:200px">
              <Option v-for="item in cityList" :value="item" :key="item">{{ item }}</Option>
            </Select>
          </FormItem> -->

          <strong style="margin-left:70px;font-size:18px">区内企业选择</strong>

          <div style="background:#FFF4EA;padding:10px 0;margin-bottom:15px">
            <FormItem label="所属片区" prop="workArea" :rules="formValidate.workArea[1] == '黄埔区'?ruleValidate.workArea:[{required:false}]">
              <RadioGroup v-model="formValidate.workArea[2]" @on-change='district_change'>
                <Radio style="min-width:20%" v-for="(item,index) in district" :key="index" :label="item.name"></Radio>
              </RadioGroup>
            </FormItem>

            <FormItem label="所属街道" prop="belongingStreet" :rules="formValidate.workArea[1] != '黄埔区'?[{required:false}]:ruleValidate.belongingStreet">
              <RadioGroup v-model="formValidate.belongingStreet">
                <Radio v-for="(item,index) in cityList" :key="index" :label="item"></Radio>
              </RadioGroup>
            </FormItem>
          </div>

          <strong style="margin-left:70px;font-size:18px">区外企业选择</strong>

          <div style="background:#FFF4EA;">
            <FormItem label="所属片区" style="padding:20px 0" prop="workArea" :rules="formValidate.workArea[1] != '黄埔区'?ruleValidate.workArea:[{required:false}]">
              <Cascader :data="workArea_data" trigger="hover" v-model="formValidate.new_workArea" style="width: 200px;" :clearable="false" @on-change='new_street_change'></Cascader>
            </FormItem>
          </div>

          <p style="margin-bottom:20px;font-size:14px;color:rgb(255, 131, 0);text-align:center">请企业按照自身情况在以上两个选项中选择其中一个进行填写</p>

          <FormItem label="工作地点" prop="address">
            <div class="address">
              <Input v-model="formValidate.address" style="width:90%" placeholder="请点击右边图标选择工作地点"></Input>
              <div style="width:10%">
                <img @click="open_Modal" src="@/assets/images/address.png" alt="">
              </div>

            </div>

          </FormItem>

          <FormItem label="结算方式">
            <Select v-model="formValidate.clearingform" placeholder="结算方式">
              <Option :value="item.id" v-for="item in clearingform" :key="item.id">{{item.name}}</Option>
            </Select>
          </FormItem>

          <FormItem label="薪酬标准" prop="salary">
            <Input v-model="formValidate.salary" placeholder="薪酬标准" maxlength="5">
            <span slot="append">元/小时</span>
            </Input>
          </FormItem>

          <FormItem label="福利待遇">
            <CheckboxGroup v-model="welfare">
              <Checkbox label="工作餐"></Checkbox>
              <Checkbox label="交通车"></Checkbox>
              <Checkbox label="住宿"></Checkbox>
            </CheckboxGroup>

            <Checkbox v-model="check_welfare">
              其他福利
              <Input v-model="other_welfare" style="width:80px"></Input>
            </Checkbox>
          </FormItem>

          <FormItem label="职位描述" prop="description">
            <Input v-model="formValidate.description" type="textarea" :autosize="{minRows: 5,}" placeholder="职位描述"></Input>
          </FormItem>

          <!-- !!!!!!!!!!! -->

          <FormItem style="text-align:right">
            <Button type="warning" @click="handleSubmit('formValidate')">{{$route.query.id?'保存修改':'提交发布'}}</Button>
            <!-- <Button @click="handleReset('formValidate')" style="margin-left: 8px">Reset</Button> -->
          </FormItem>

        </Form>
      </div>
    </section>
    <Modal v-model="address_model" title="地图选点" :styles="{top: '20px'}" footer-hide>
      <iframe id="mapPage" width="100%" height="800px" frameborder=0 src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=4YYBZ-BIGCG-IMDQT-IOPQG-R77A6-KOFNR&referer=myapp">
      </iframe>
    </Modal>
  </div>
</template>

<script>
import { data } from "./job_full"
import { district } from "@/libs/public"
import "./hackmap/KOFNR"
import "./hackmap/geolocation"
export default {
  data() {
    return {
      spinShow: false,
      district,
      daytime: [
        {
          name: "4小时",
          id: "4小时"
        },
        {
          name: "8小时",
          id: "8小时"
        },
        {
          name: "12小时",
          id: "12小时"
        },
        {
          name: "其他",
          id: "其他"
        },
      ],
      order: [
        {
          name: "白班",
          id: "白班"
        },
        {
          name: "两班倒",
          id: "两班倒"
        },
      ],
      minExperience: [
        {
          name: "不限",
          id: "不限"
        },
        {
          name: "一年以下",
          id: "一年以下"
        },
        {
          name: "1-3年",
          id: "1-3年"
        },
        {
          name: "3-5年",
          id: "3-5年"
        },
        {
          name: "5年以上",
          id: "5年以上"
        },

      ],
      education: data.education,
      workArea_data: data.workArea_data,
      clearingform: [
        {
          name: "日结",
          id: "日结"
        },
        {
          name: "周结",
          id: "周结"
        },
        {
          name: "月结",
          id: "月结"
        },
        {
          name: "其他",
          id: "其他"
        },

      ],

      formValidate: {
        address: '',
        workArea: ["广州市", "黄埔区", "东区"],
        new_workArea: [],
        belongingStreet: "云埔街道",
        gps: {
          x: 1,
          y: 1
        }
      },
      ruleValidate: {
        name: [
          { required: true, message: '请填写职位名称', trigger: 'blur' }
        ],
        workhours: [
          { required: true, message: '请填写工作时长', trigger: 'blur' }
        ],
        daytime: [
          { required: true, message: '请选择每日工作时间', trigger: 'change' }
        ],
        workArea: [
          { required: true, message: '请选择所属地区', }
        ],
        belongingStreet: [
          { required: true, message: '请填写所属街道', trigger: 'change' }
        ],
        salary: [
          { required: true, message: '薪酬标准', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请填写职位描述', trigger: 'blur' },
        ],
        address: [
          { required: true, message: '请选择工作地点', trigger: 'change' }
        ],
      },

      //福利
      welfare: [],
      check_welfare: false,
      other_welfare: '',


      //Modal
      address_model: false,
    }
  },
  computed: {
    cityList() {
      let val = this.formValidate.workArea[2]
      let arr = val == '东区' ? ['云埔街道', '南岗街道'] : val == '西区' ? ['穗东街道', '夏港街道'] : val == '科学城' ? ['联和街道', '萝岗街道', '长岭街道'] : val == '永和' ? ['永和街道', '永宁街道'] : val == '知识城' ? ['龙湖街道', '新龙街道', '九佛街道'] : val == '老黄埔' ? ['文冲街道', '大沙街道', '红山街道'] : val == '区内其他' ? ['鱼珠街道', '长洲街道', '其他街道'] : []
      return arr
    }
  },
  methods: {

    district_change(val) {
      this.formValidate.workArea = []
      this.formValidate.new_workArea = []
      this.formValidate.workArea[0] = '广州市'
      this.formValidate.workArea[1] = '黄埔区'
      this.formValidate.workArea[2] = val
      this.formValidate.belongingStreet = ''

    },
    new_street_change(val1) {
      console.log(val1);
      this.formValidate.workArea = val1
    },

    handleSubmit(name) {

      this.$refs[name].validate((valid) => {

        if (valid) {


          //组合其他福利数组
          let new_welfare = JSON.parse(JSON.stringify(this.welfare))
          if (this.check_welfare && this.other_welfare) {
            new_welfare.push('')
            new_welfare.push(this.other_welfare)
          }
          this.formValidate.welfare = new_welfare

          this.formValidate.mobile = localStorage.getItem('firm_mobile')

          if (this.$route.query.id) {

            //修改
            this.$Modal.confirm({
              title: "该职位已发布，修改职位后需重新审核",
              closable: true,
              onOk: () => {
                this.formValidate._id = this.$route.query.id
                this.spinShow = true
                this.app("enterpriseOperators", "updateTemporaryPosition", this.formValidate).then(res => {
                  console.log(res);
                  this.$Message.warning({
                    content: res.message,
                    duration: 4
                  })
                  if (res.code == 200) {
                    this.$router.push({ name: 'job_manage' })
                  }
                  this.spinShow = false

                })
              }
            })


          } else {
            //发布新职位
            this.spinShow = true
            this.app("enterpriseOperators", "addTemporaryPosition", this.formValidate).then(res => {
              console.log(res);
              this.$Modal.warning({
                title: res.message
              })
              if (res.code == 200) {
                this.$router.push({ name: 'job_manage' })
              }
              this.spinShow = false
            })
          }
        } else {
          this.$Message.error('请填写齐全资料');
        }
      })
    },
    //封装获取数据
    get_details() {
      this.spinShow = true
      this.app("enterpriseOperators", "getPosition", {
        _id: this.$route.query.id
      }).then(res => {
        console.log(res);
        this.formValidate = res.result
        this.formValidate.new_workArea = this.formValidate.workArea
        this.formValidate.salary = res.result.minSalary

        //整合福利待遇数组
        if (res.result.welfare[0]) {
          let bool_welfare = true
          res.result.welfare.map((item, index) => {
            if (!item) {
              this.check_welfare = true
              this.other_welfare = res.result.welfare[index + 1]
              this.welfare = res.result.welfare.slice(0, index)
              bool_welfare = false
            }
          })
          //没有其他福利 直接赋值
          if (bool_welfare) {
            this.welfare = res.result.welfare
          }
        } else if (res.result.welfare.length > 0) {
          this.check_welfare = true
          this.other_welfare = res.result.welfare[1]
          this.formValidate.welfare = []
        }

        this.spinShow = false
      })

    },

    //地图选点返回事件
    onMessage(event) {
      let loc = event.data
      if (loc && loc.module == 'locationPicker') {
        //防止其他应用也会向该页面post信息，需判断module是否为
        console.log(loc);
        //选点完毕关闭Modal
        this.address_model = false
        this.formValidate.address = loc.poiaddress + loc.poiname
        console.log(this.formValidate.address);
        this.formValidate.gps.x = loc.latlng.lng
        this.formValidate.gps.y = loc.latlng.lat
      }
    },
    //打开地图选点
    open_Modal() {
      this.address_model = true
    },
  },

  created() {
    if (this.$route.query.id) {
      this.get_details()
    } else {
      this.formValidate.address = localStorage.getItem("firm_address")
      if (JSON.parse(localStorage.getItem("firm_gps"))) {
        this.formValidate.gps = JSON.parse(localStorage.getItem("firm_gps"))
      }
      // this.formValidate.workArea = JSON.parse(localStorage.getItem("firm_street"))
    }
  },

  mounted() {
    //接收来自其他页面的消息并判断发送者
    window.addEventListener("message", this.onMessage);
  },
}
</script>

<style lang="less" scoped>
@import "./job_Temporary.less";
</style>
